import React from "react"
import { styled } from "@mui/system"
import { TextAccent } from "../styles"


const Wrapper = styled("div")({
  display: "flex",
  background: "#F8F8F8",
  padding: "55px 28px 51px 28px",
  "@media(max-width: 576px)": {
    paddingTop: "53px",
    paddingBottom: "47px",
  },
})

const InnerBlock = styled("div")({
  maxWidth:"1280px",
  margin: "0 auto",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  gap: 50,
  alignItems: "center",
  "@media(max-width:1024px)": {
    justifyContent: "center",
    gap: 40
  },
  "@media(max-width: 576px)": {
    maxWidth:"100vw",
    flexDirection: "column",
    gap: "3.07vw",
    alignItems: "center"
  },
})
  

const MoneyText = styled(TextAccent)({
    display: "flex",
    fontFamily: 'Heiti TC',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "86px",
    lineHeight: "120%",
    letterSpacing: "-0.01em",
    "@media(max-width:1024px)": {
      fontSize: 80
    },

    "@media(max-width: 576px)": {
        fontSize: "15.38vw",
        paddingLeft: "0px",
        margin: "0 auto",
      },
})

const Description = styled("div")({
    display: "flex",
    fontFamily: 'Merriweather',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "36px",
    lineHeight: "150%",
    color: "#222222",
    paddingTop: "5px",
    paddingBottom: "19px",
    "@media(max-width:1024px)": {
      fontSize: 24
    },
    "@media(max-width: 576px)": {
        width: "auto",
        textAlign: "center",
        fontSize: "5.64vw",
        margin: "0 auto",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
})

const StartWord = styled("div")({
  fontFamily: "Marriweather",
  fontSize:42,
  lineHeight: "120%",
  color: "#222",
  flexShrink: 0,

  "@media(max-width:1024px)": {
    fontSize: 32
  },
})

const JobsBlock = () => {
  return (
    <Wrapper>
      <InnerBlock>
        <StartWord>
          For every
        </StartWord>
        <MoneyText>$6300</MoneyText>
        <Description>
          of Graphene's investment <br />a new job is created.
        </Description>
      </InnerBlock>
    </Wrapper>
  )
}

export default JobsBlock
