import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {styled} from "@mui/system";
import MicroIcon from "../../../static/svg/micro.svg"
import TickImage from "../../../static/svg/tick.svg"
import RecReadyIcon from "../../../static/svg/rec-ready.svg"
import RecStopIcon from "../../../static/svg/rec-stop.svg"
import PlayReadyIcon from "../../../static/svg/play-ready.svg"
import PlayStopIcon from "../../../static/svg/play-stop.svg"

import {ModalWrap, OutlinedInputContent, OutlinedInputWrap, OutlinedTitle} from "../../styles";
import {Button, IconButton} from "@mui/material";


const Description = styled("div")({
    textAlign: "center",
    maxWidth: "370px",
    marginTop: "25px",
    fontWeight: "300",
    fontFamily: "Merriweather",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#0870E0",
    userSelect: "none",
    "@media(max-width: 576px)": {
        padding: "0 40px",
        marginTop: "16px"
    }
})

const MicroImage = styled(MicroIcon)({
    ":hover": {
        cursor: "pointer",
    }
})

const ModalWindow = styled("div")({
    background: "#FFFFFF",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #0870E0",
    borderRadius: 4,
    padding: 10
})

const CloseButton = styled(Button)({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 0,
    fontFamily: "Merriweather",
    fontSize: 10,
    fontWeight: 700,
    color: "#0870E0",
    opacity: 0.5,
})

const VideoInputContent = styled(OutlinedInputContent)({
    padding: "59px 0",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
})

const LinkButton = styled(Button)({
    fontFamily: "Merriweather",
    fontWeight: 700,
    textTransform: "inherit",
    textDecoration: "underline",
    textUnderlineOffset: "7px",
    textDecorationColor: "#E3E3E3",

    "&:hover": {
        textDecoration: "underline",
        textDecorationColor: "#0870E0",
        background: "none"
    }
})

const Header = styled("div")({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
})

const StyledIconButton = styled(IconButton)({
    width: 74,
    height: 74,
    position: "absolute",
    bottom: 20,
})

const isSafari = () =>{
    //this code block works on client only
    if (typeof window !== 'undefined'){
        const chromeAgent = navigator?.userAgent.indexOf("Chrome") > -1;
        const safariAgent = navigator?.userAgent.indexOf("Safari") > -1;

        return (!chromeAgent) && (safariAgent)
    }
}

const VideoRecordInput = ({formValues, setFormValues}) => {

    //EMPTY, RECORDING, PLAYING
    const [pitchStatus, setPitchStatus] = useState("EMPTY")
    const [recVideoBlob, setRecVideoBlob] = useState(null)
    //rec time in secs
    const [recTime, setRecTime] = useState(0)

    useEffect(() => {
        if (recVideoBlob) {
            const videoFile = new File([recVideoBlob], "pitchFile.mp4", {type: "video/mp4"})
            setFormValues({...formValues, "pitchFile": videoFile})
        } else {
            setFormValues({...formValues, "pitchFile": null})
        }
    }, [recVideoBlob]);


    return (
        <OutlinedInputWrap style={{gridColumn: "1 / span 2"}}>
            <OutlinedTitle>
                Tell us about yourself (OPTIONAL)
            </OutlinedTitle>
            <VideoInputContent>
                {recVideoBlob === null ?
                    <>
                        <MicroImage onClick={() => setPitchStatus("RECORDING")}/>
                        <Description>
                            Record 1 min elevator pitch.
                        </Description>
                    </>
                    :
                    <>
                        <TickImage/>
                        <Description>
                            Your recording is saved.
                        </Description>
                        <div>
                            {
                                isSafari()
                                    ?
                                    <a href={URL.createObjectURL(recVideoBlob)} download={"pitch.mp4"}>
                                        <LinkButton variant={"text"}>
                                            Download video
                                        </LinkButton>
                                    </a>
                                    :
                                    <LinkButton variant={"text"} onClick={() => setPitchStatus("PLAYING")}>
                                        View video
                                    </LinkButton>
                            }


                            <LinkButton variant={"text"} onClick={() => {
                                setRecVideoBlob(null)
                                setRecTime(0)
                            }}>
                                Delete recording
                            </LinkButton>
                        </div>
                    </>
                }
                <div>
                </div>
                {
                    pitchStatus === "RECORDING" &&
                    <RecWindow setPitchStatus={setPitchStatus} setRecordedVideo={setRecVideoBlob}
                               setRecTime={setRecTime}/>
                }

                {
                    !isSafari() && pitchStatus === "PLAYING" && <Player recTime={recTime} recVideo={recVideoBlob} setPitchStatus={setPitchStatus}/>
                }
            </VideoInputContent>
        </OutlinedInputWrap>
    )
}

const RecWindow = ({setRecordedVideo, setPitchStatus, setRecTime}) => {

    const videoRef = useRef()
    const recBtnRef = useRef()
    const stopBtnRef = useRef()
    const closeBtnRef = useRef()

    //INACTIVE, STARTED
    const [recStatus, setRecStatus] = useState("INACTIVE")


    useEffect(() => {
        if (navigator?.mediaDevices?.getUserMedia) {
            let mediaRecorder

            navigator.mediaDevices.getUserMedia({
                audio: true, video: {
                    facingMode: "user"
                }
            }).then(stream => {
                const parts = []

                const startRecording = () => {
                    mediaRecorder.start(1000)
                    mediaRecorder.ondataavailable = (e) => {
                        parts.push(e.data)
                    }
                    setTimeout(stopRecording, 60000)
                }
                const stopRecording = () => {
                    if (mediaRecorder.state !== "inactive"){
                        mediaRecorder?.stop()
                        const blob = new Blob(parts, {type: "video/webm"})
                        setRecordedVideo(blob)
                        setPitchStatus("PLAYING")
                    }
                    closeVideoStream()
                }

                const closeVideoStream = () => {
                    // close all tracks from the MediaStream
                    stream.getTracks().forEach(track => track.stop());
                    setPitchStatus("EMPTY")
                }

                mediaRecorder = new MediaRecorder(stream)
                if ("srcObject" in videoRef.current) {
                    videoRef.current.srcObject = stream
                }

                recBtnRef.current?.addEventListener('click', startRecording)

                stopBtnRef.current?.addEventListener('click', stopRecording)

                closeBtnRef.current?.addEventListener('click', closeVideoStream)
            })
        } else {
            console.log("getUserMedia is unsupported")
        }
    }, []);

    return (
        <ModalWrap>
            <ModalWindow>
                <Header>
                    <CountdownTimer start={recStatus === "STARTED"}
                                    isRec={recStatus === "STARTED"} setRecTime={setRecTime}
                                    initTime={60}/>
                    <CloseButton ref={closeBtnRef} onClick={() => setRecStatus("")}>Close</CloseButton>
                </Header>
                <video width={640} height={480} autoPlay muted ref={videoRef}></video>
                <StyledIconButton sx={{display: recStatus === "INACTIVE" ? "" : "none"}} ref={recBtnRef}
                                  onClick={() => setRecStatus("STARTED")}>
                    <RecReadyIcon/>
                </StyledIconButton>
                <StyledIconButton sx={{display: recStatus === "STARTED" ? "" : "none"}} ref={stopBtnRef}
                                  onClick={() => setRecStatus("FINISHED")}>
                    <RecStopIcon/>
                </StyledIconButton>
            </ModalWindow>
        </ModalWrap>
    )
}

const Player = ({recVideo, recTime, setPitchStatus}) => {
    //PAUSED,PLAYING
    const [playStatus, setPlayStatus] = useState("PAUSED")
    const [videoURL, setVideoURL] = useState("")
    const [reset, setReset] = useState(true)

    const videoRef = useRef()

    useEffect(() => {
        setVideoURL(URL.createObjectURL(recVideo))
    }, []);


    const playVideo = () => {
        setPlayStatus("PLAYING")
        videoRef.current?.play()
    }

    const stopVideo = () => {
        setReset(false)
        setPlayStatus("PAUSED")
        videoRef.current?.pause()
    }

    const resetVideo = () =>{
        setPlayStatus("PAUSED")
        setReset(true)
    }

    return (
        <ModalWrap>
            <ModalWindow>
                <Header>
                    <CountdownTimer start={playStatus === "PLAYING"} isRec={false} initTime={recTime} reset={reset}/>
                    <CloseButton onClick={()=> setPitchStatus("EMPTY")}>Close</CloseButton>
                </Header>
                <video width={640} height={480} src={videoURL} ref={videoRef} onEnded={resetVideo}></video>
                <StyledIconButton sx={{display: playStatus === "PAUSED" ? "" : "none"}}
                                  onClick={playVideo}
                >
                    <PlayReadyIcon/>
                </StyledIconButton>
                <StyledIconButton sx={{display: playStatus === "PLAYING" ? "" : "none"}}
                                  onClick={stopVideo}>
                    <PlayStopIcon/>
                </StyledIconButton>
            </ModalWindow>
        </ModalWrap>
    )
}


const CountdownTimer = ({start, isRec, initTime, setRecTime, reset=true}) => {

    const TimerStyled = styled("div")({
        fontFamily: "Heiti TC",
        fontSize: 24,
        padding: "6px 6px 2px",
        marginBottom: 10,
        borderRadius: 2,

        "&.active": {
            background: "red",
            color: "#FFFFFF"
        }
    })

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        if (start) {
            let myInterval = setInterval(() => {
                if (seconds > 0)
                    setSeconds(seconds - 1)

                if (isRec)
                    setRecTime(prev => prev + 1);

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval)
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
            }, 1000)
            return () => {
                clearInterval(myInterval);
            };
        }
    });

    useEffect(() => {
        if (reset){
            if (initTime >= 60) {
                setMinutes(initTime / 60)
                setSeconds(initTime - (initTime / 60) * 60)
            } else {
                if (initTime) {
                    setMinutes(0)
                    setSeconds(initTime)
                } else {
                    setMinutes(1)
                    setSeconds(0)
                }
            }
        }
    }, [isRec, start]);


    return (
        <TimerStyled
            className={start && isRec ? "active" : ""}> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</TimerStyled>
    )
}

export default VideoRecordInput
