import * as React from "react"
import {styled} from "@mui/system";
import {Button} from "@mui/material";
import {useEffect, useState} from "react";


const GroupWrap = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    maxWidth:"366px",
    columnGap:"30px",
    rowGap: "22px"
})
const AnswerButton = styled(Button)({
    //mui base color reset
    color: "unset",
    "&.MuiButton-outlined": {
        textTransform: "capitalize",
        display: "inline-flex",
        justifyContent: "flex-start",
        border: "1px solid #E3E3E3",
        borderRadius: "4px",
        width:"100%",
        maxWidth: "168px",
        height: "50px",

        "&:hover": {
            background: "inherit",
            border: "1px solid #0870E0",

            //badge styles
            "& :first-child": {
                border: "1px solid #0870E0",
            }
        },

        //to color as smoothly as the button in mui
        "& :first-child":{
            transition: "border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        },

        "&:focus":{
            backgroundColor: "rgba(8, 112, 224, 0.08)",
        }
    },

    "&.error":{
        border: "1px solid #D8001B;"
    }
})

const ButtonLabel = styled("div")({
    color: "#0870E0",

    fontFamily: "Merriweather",
    fontSize: "18px",
    fontWeight: 300,
    lineHeight: "22.63px",

    "@media(max-width:576px)":{
        fontSize: "16px",
    }
})

const AnswerBadge = styled("div")({
    width: "26px",
    height: "26px",
    flexShrink: 0,

    border: "1px solid #E3E3E3",
    borderRadius: "2px",

    fontFamily: "Helvetica",
    fontSize: "14px",
    fontWeight: 700,

    marginLeft: "-5px",
    marginRight: "11px",
})

const BadgesText = ["A","B","C","D"]

const ButtonsGroup = ({questionData, setFormValues, errors, setErrors, isValidating}) => {

    const [selectedItem, setSelectedItem] = useState("");

    const handleSelect = (option, answer) =>{
        setSelectedItem(option)
        setFormValues((prev)=>({...prev, [questionData.id]:answer}))

        const newErrors = Object.assign([], errors)
        const deleteItemIndex = newErrors.findIndex(i => i === questionData.id)

        if (deleteItemIndex > -1)
            newErrors.splice(deleteItemIndex, 1)

        setErrors(newErrors)
    }

    return (
        <GroupWrap>
            {questionData.options.map( (option, index)=> {
                    const badgeText = BadgesText[index]
                    return <AnswerButton
                        variant={"outlined"}
                        onClick={()=>handleSelect(option, option)}
                        className={errors.find(i => i === questionData.id) && isValidating ? "error" : ""}
                        sx={{backgroundColor: selectedItem === option ? "rgba(8, 112, 224, 0.08)" : "transparent",}}>
                        <AnswerBadge
                            sx={{
                                backgroundColor: selectedItem === option ? "#0870E0" : "transparent",
                                color: selectedItem === option ? "#FFFFFF" : "#000000",
                                border: selectedItem === option ? "1px solid #0870E0" : "1px solid #E3E3E3",
                            }}>
                            {badgeText}
                        </AnswerBadge>
                        <ButtonLabel>
                            {questionData.options[index]}
                        </ButtonLabel>
                    </AnswerButton>
                }
            )}
        </GroupWrap>
    )
}

export default ButtonsGroup