import * as React from "react"
import Map from "../../../static/svg/Map.svg"
import { styled } from "@mui/system"
import { TextAccent } from "../../styles"

const Wrap = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "1280px",
  gap: "60px",
  margin: "0 auto",

  "@media(max-width: 1336px)": {
    padding: "0 28px",
  },

  "@media(max-width: 1024px)": {
    flexDirection: "column",
    gap: "28px",
    padding: "0 40px",
  },
})

const Description = styled("div")({
  maxWidth: "565px",
  fontFamily: "Merriweather",

  fontSize: "24px",
  lineHeight: "190%",

  "@media(max-width: 576px)": {
    fontSize: "14px",
  },
})

const WorldMap = styled(Map)({
  maxWidth: "630px",
  width: "100%",
  height: "100%",
})

const MiddleEastInfo = () => {
  return (
    <Wrap>
      <WorldMap />
      <Description>
        Following our hands-on approach, we help our partners{" "}
        <TextAccent>with a soft landing in MENA, LATAM and US</TextAccent>. We
        also connect them with other possible investors, helping to raise the
        next rounds.
      </Description>
    </Wrap>
  )
}

export default MiddleEastInfo
