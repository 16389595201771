import * as React from "react"
import {styled} from "@mui/system";
import LoadingButton from '@mui/lab/LoadingButton';
import {OutlinedInputContent, OutlinedInputWrap, OutlinedTitle, TextAccent, TitleAccent} from "../../styles";
import ButtonsGroup from "./buttonsGroup";
import {emailValidation, nameValidation, phoneValidation, StyledTextField, textValidation} from "../formTextField";
import VideoRecordInput from "./videoRecordInput";
import {Button, IconButton, TextField} from "@mui/material";
import {useState} from "react";
import CrossIcon from "../../../static/svg/Cross.svg"
import CaptionText from "../../lib/captionText";
import TickContained from "./../../../static/svg/tick_contained.svg"
import axios from "axios"

const Wrap = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    gap: "40px",
    width: "100%",
    maxWidth: "1280px",
    margin: "0 auto",

    "@media(max-width: 1336px)": {
        padding: "0 28px"
    },

    "@media(max-width: 1024px)": {
        padding: "0 40px"
    },

    "@media(max-width: 576px)": {
        flexDirection: "column",
    }
})

const Information = styled("div")({
    maxWidth: "570px",
    flexShrink: 2,
})
const Title = styled(TitleAccent)({
    lineHeight: "120%",
    letterSpacing: "-1%",
})

const Description = styled("div")({
    marginTop: "48px",
    fontFamily: "Merriweather",
    fontSize: "24px",
    lineHeight: "190%",

    "@media(max-width: 576px)": {
        marginTop: "24px",

        fontSize: "14px"
    }
})

const Form = styled("div")({
    flexShrink: 1,
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    maxWidth: 630,

    "@media(max-width:576px)": {
        gap: "30px"
    }
})

const FormField = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 20,

    "@media(max-width:576px)": {
        gap: "20px"
    }
})

const Question = styled("div")({
    fontFamily: "Merriweather",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "190%",

    "@media(max-width: 576px)": {
        fontSize: "14px",
    }
})

const SuccessMessage = styled("div")({
    fontFamily: "Heiti TC",
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: "-0.01em",
    color: "#0870E0",
})

const BottomBar = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    alignItems: "center"
})

const Fields = styled("div")({
    display: "grid",
    marginTop: 21,
    rowGap: "27px",
    columnGap: "20px",
    gridTemplateColumns: "1fr 1fr",

    "@media(max-width: 576px)": {
        display: "flex",
        flexDirection: "column",
        gap: 20
    }
})

const ContactsForm = styled(Fields)({
    gridTemplateAreas: `
    "first last"
    "phone email"
    "pitch pitch"
    `,
})

const BusinessPlanForm = styled(Fields)({
    gridTemplateColumns: "50% 1fr",
    gridTemplateAreas: `
    "name site"
    "file link"
    "text text"
    `,
})

const SendButton = styled(LoadingButton)({
    backgroundColor: "#0870E0",
    borderRadius: "4px",

    fontFamily: "Heiti TC",
    fontSize: "16px",
    fontWeight: 400,
    width: "90px",
    height: "40px",

    "@media(max-width: 576px)": {
        marginTop: "6px"
    },

    "&.success": {
        backgroundColor: "green"
    },

    "&.error": {
        backgroundColor: "rgba(216, 0, 27, 1)"
    }
})

const ApplyForFunding = ({questionsData}) => {
    const initialState = {
        isTechStartup: "",
        whereBased: "",
        fundingStage: "",
        firstName: "",
        lastName: "",
        phoneNumber: "+",
        email: "",
        pitchFile: null,
        pitchLink: "",
        companyName: "",
        companyWebsite: "",
        businessPlanFile: null,
        description: ""
    }

    const [formValues, setFormValues] = useState(initialState)
    //UNSENT, SENDING, SUCCESS, ERROR
    const [formStatus, setFormStatus] = useState("UNSENT")
    const [validating, setIsValidating] = useState(false)

    const [errors, setErrors] = useState([
        "isTechStartup",
        "whereBased",
        "fundingStage",
        "firstName",
        "lastName",
        "phoneNumber",
        "email",
        "companyName",
        "companyWebsite",
        "description",
        "businessPlanFile"
    ])

    const findError = (name) => {
        return errors.find(i => i === name)
    }

    const handleTextInputChange = (e) => {
        const targetId = e.target.id
        let value = e.target.value

        if (targetId === "phoneNumber") {
            const numbers = value.substr(1)
            value = "+" + numbers.replace(/\D/g, '')
        }

        setFormValues({...formValues, [targetId]: value})

        const validateMethod = (value) => {
            switch (targetId) {
                case "firstName":
                    return nameValidation(value)
                case "lastName":
                    return nameValidation(value)
                case "email":
                    return emailValidation(value)
                case "phoneNumber":
                    return phoneValidation(value)
                default:
                    return textValidation(value)
            }
        }

        if (validateMethod(value)) {
            const newErrors = Object.assign([], errors)
            const deleteItemIndex = newErrors.findIndex(i => i === targetId)
            if (deleteItemIndex > -1)
                newErrors.splice(deleteItemIndex, 1)
            setErrors(newErrors)
        }
    }

    const handleSubmit = () => {
        setIsValidating(true)
        if (errors.length === 0) {
            setFormStatus("SENDING")
            const apiURL = "https://admin.graphenevc.com/forms/apply-for-funding"
            // const apiURL = "http://localhost:1337/forms/apply-for-funding"
            const data = {
                "tech-startup": formValues.isTechStartup,
                "startup-base": formValues.whereBased,
                "funding-stage": formValues.fundingStage,
                "first-name": formValues.firstName,
                "last-name": formValues.lastName,
                "phone-number": formValues.phoneNumber,
                "email": formValues.email,
                "video-pitch-link": formValues.pitchLink,
                "company-name": formValues.companyName,
                "company-website": formValues.companyWebsite,
                "project-description": formValues.description
            }
            var bodyFormData = new FormData()
            bodyFormData.append('data', JSON.stringify(data))
            if (formValues.pitchFile)
                bodyFormData.append(`files.pitch`, formValues.pitchFile, `${formValues.companyName.replace('.', '')}-pitch`)

            bodyFormData.append(`files.business-plan`, formValues.businessPlanFile, formValues.businessPlanFile.name)
            axios({
              method: "post",
                url: apiURL,
                data: bodyFormData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response) {
                    if (response.status === 200)
                        setFormStatus("SUCCESS")
                    console.log(response)
                })
                .catch(function (error) {
                    setFormStatus("ERROR")
                    console.log(error)
                })
        }
    }


    return (
        <Wrap>
            <Information>
                <Title>
                    Apply For Funding
                </Title>
                <Description>
                    <p>
                        Thinking we are a good fit to become your partner in propelling your startup? <TextAccent>
                        To be considered for funding, please send a pitch deck via the form on this page.
                    </TextAccent>
                    </p>
                    <p>
                        We are not looking for long, compex or fancy decks but we need your brevity and clarity of
                        thought — please help us evaluate your idea as quick and easy as possible. The clearer your
                        deck is the more chances you have to move forward.
                    </p>
                    <p>
                        As a matter of policy, we don’t sign non-disclosure agreements (NDAs), but nothing you send us
                        will be shared outside Graphene Ventures without your permission.
                    </p>
                    <p>
                        Every pitch deck within our investment areas will be reviewed. Due to the volume
                        of decks and materials, we may take one week to get back to you with initial feedback.
                    </p>
                    <CaptionText style={{marginTop: 132}}>
                        We are always just a click away!
                    </CaptionText>
                </Description>
            </Information>
            <Form>
                {questionsData.map(item => (
                    <FormField>
                        <Question>
                            {item.question}
                        </Question>
                        <ButtonsGroup key={item.question}
                                      questionData={item}
                                      errors={errors}
                                      setErrors={setErrors}
                                      isValidating={validating}
                                      setFormValues={setFormValues}/>
                    </FormField>
                ))}
                <FormField>
                    <Question>
                        Your best contacts
                    </Question>
                    <ContactsForm>
                        <StyledTextField style={{gridArea: "first"}}
                                         label={"FIRST NAME"}
                                         id={"firstName"}
                                         error={!!findError("firstName") && validating}
                                         onChange={handleTextInputChange}
                                         InputLabelProps={{shrink: true}}/>
                        <StyledTextField style={{gridArea: "last"}}
                                         label={"LAST NAME"}
                                         id={"lastName"}
                                         error={!!findError("lastName") && validating}
                                         onChange={handleTextInputChange}
                                         InputLabelProps={{shrink: true}}/>
                        <StyledTextField label={"PHONE NUMBER"}
                                         id={"phoneNumber"}
                                         error={!!findError("phoneNumber") && validating}
                                         onChange={handleTextInputChange}
                                         value={formValues.phoneNumber}
                                         InputLabelProps={{shrink: true}}/>
                        <StyledTextField style={{gridArea: "email"}}
                                         label={"EMAIL"}
                                         id={"email"}
                                         error={!!findError("email") && validating}
                                         onChange={handleTextInputChange}
                                         InputLabelProps={{shrink: true}}/>
                        <VideoRecordInput style={{gridArea: "pitch"}} setFormValues={setFormValues}
                                          formValues={formValues}/>
                    </ContactsForm>
                </FormField>
                <FormField>
                    <Question>
                        And Your pitch deck
                    </Question>
                    <BusinessPlanForm>
                        <StyledTextField style={{gridArea: "name"}}
                                         label={"COMPANY NAME"}
                                         id={"companyName"}
                                         error={!!findError("companyName") && validating}
                                         onChange={handleTextInputChange}
                                         InputLabelProps={{shrink: true}}/>
                        <StyledTextField style={{gridArea: "site"}}
                                         label={"COMPANY WEBSITE"}
                                         id={"companyWebsite"}
                                         error={!!findError("companyWebsite") && validating}
                                         onChange={handleTextInputChange}
                                         InputLabelProps={{shrink: true}}/>
                        <FileUploadInput setFormValues={setFormValues}
                                         error={findError("businessPlanFile") && validating}
                                         setErrors={setErrors}
                                         errors={errors}
                                         formValues={formValues}/>
                        <StyledTextField style={{gridArea: "link"}}
                                         label={"VIDEO PITCH LINK (OPTIONAL)"}
                                         id={"pitchLink"}
                                         onChange={handleTextInputChange}
                                         InputLabelProps={{shrink: true}}/>
                        <StyledTextField style={{gridArea: "text", gridColumnSpan: 2}}
                                         label={"BRIEFLY DESCRIBE YOUR PROJECT"}
                                         id={"description"}
                                         error={!!findError("description") && validating}
                                         onChange={handleTextInputChange}
                                         multiline
                                         minRows={3}
                                         InputLabelProps={{shrink: true}}/>
                    </BusinessPlanForm>
                    <BottomBar>
                        <SendButton variant={"contained"}
                                    loading={formStatus === "SENDING"}
                                    className={formStatus === "SUCCESS" && "success" || formStatus === "ERROR" && "error"}
                                    onClick={handleSubmit}>
                            SEND
                        </SendButton>
                        {formStatus === "SUCCESS" &&
                            <>
                                <TickContained style={{marginLeft: "auto", marginRight: 10}}/>
                                <SuccessMessage>
                                    Your message has been sent successfully!
                                </SuccessMessage>
                            </>
                        }
                    </BottomBar>
                </FormField>
            </Form>
        </Wrap>
    )
}

const FileUploadInput = ({setFormValues, formValues, error, setErrors, errors}) => {
    const BrowseButton = styled(Button)({
        color: "#0870E0",
        fontFamily: "Helvetica",
        fontWeight: 700,
        flexShrink: 0,
        borderRadius: 2,
        border: "1px solid #D2D7DD",
        "&:hover": {
            border: "1px solid #0870E0"
        }
    })


    const [fileName, setFileName] = useState("");
    const [inputValue, setInputValue] = useState("")

    const getFileName = (e) => {
        setInputValue(e.target.value)
        setFormValues({...formValues, businessPlanFile: e.target.files[0]})
        setFileName(e.target.value.split("\\").pop())
        const newErrors = Object.assign([], errors)
        const deleteItemIndex = errors.findIndex(i => i === "businessPlanFile")

        newErrors.splice(deleteItemIndex, 1)
        setErrors(newErrors)
    }

    const deleteFile = () => {
        setInputValue("")
        setErrors([...errors, "businessPlanFile"])
        setFormValues({...formValues, "businessPlanFile": null})
        setFileName("")
    }
    const fileNameStyles = {overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}

    const fileUploadContent = {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row-reverse",
        alignItems: "center"
    }

    return (
        <OutlinedInputWrap style={{gridArea: "file"}}>
            <OutlinedTitle className={error ? "error" : ""}>
                ATTACH PITCH DECK
            </OutlinedTitle>
            <OutlinedInputContent style={fileUploadContent} className={error ? "error" : ""}>
                {fileName === "" ? <BrowseButton variant={"outlined"} size={"small"}>
                        <label for={"file"}>
                            BROWSE
                        </label>
                    </BrowseButton>
                    :
                    <IconButton sx={{border: "1px solid #D2D7DD"}} onClick={deleteFile}>
                        <CrossIcon/>
                    </IconButton>
                }
                <TextField
                    onChange={getFileName}
                    value={inputValue}
                    style={{display: "none"}}
                    id={"file"}
                    type="file"
                />
                <div style={fileNameStyles}>
                    {fileName}
                </div>
            </OutlinedInputContent>
        </OutlinedInputWrap>
    )
}

export default ApplyForFunding