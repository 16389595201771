import React from "react"
import { styled } from "@mui/system"
import BlueRectImg from "../../static/svg/blue_grad_rect.svg"
import {LabelAccent, TextBlock, TitleAccent, Wrap} from "../styles"
import GridLines from "../gridLines";

const HeaderBlockTitle = styled(TitleAccent)({
    fontWeight: 400,
    padding: "28px 40px 0",
    maxWidth: "855px",
    textAlign: "center",

    "@media(max-width:576px)":{
        padding: "86px 40px 0"
    }
})

const HeaderBlockWrap = styled(Wrap)({
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
})

const Points = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    gap: "30px",
    paddingTop: "86px",
    width: "100%",

    "@media(max-width: 1336px)":{
      padding: "86px 28px 0px"
    },

    "@media(max-width: 1024px)": {
        justifyContent: "space-evenly",
        padding: "61px 40px 0px",
        flexWrap: "wrap",
        alignItems: "center",
    },
})

const BulletPoint = styled("div")({
    maxWidth: "354px",
    "@media(max-width: 1024px)": {
        maxWidth: "340px",
        flexFlow: "column",
        alignItems: "center",
        marginBottom: "auto",
    },
    "@media(max-width: 576px)": {
        padding: "20px 0px"
    },
})

const PointLabelAccent = styled(LabelAccent)({
    flexShrink: 0,
    fontSize: "34px",
    lineHeight: "120%",
    letterSpacing: " -0.01em",

    "@media(max-width: 576px)": {
        fontSize: "26px",
    },
})

const PointHeader = styled("div")({
    display: "flex",
    alignItems: "center",
})

export const Caption = styled(TextBlock)({
    textAlign: "center",
    fontSize: "24px",
    lineHeight: "190%",
    "@media(max-width: 1024px)": {
        fontSize: "18px",
        padding: "0 20px"
    },
})

export const Rectangle = styled(BlueRectImg)({
    paddingLeft: "20px",
})

const HeaderBlock = ({title, points,...props}) => {

  return (
    <HeaderBlockWrap>
      <HeaderBlockTitle>{title}</HeaderBlockTitle>
      <Points>
          {points.map(pointData => <BulletPoint>
              <PointHeader>
                  <PointLabelAccent>{pointData.label}</PointLabelAccent>
                  <Rectangle/>
              </PointHeader>
              <TextBlock>
                  {pointData.body}
              </TextBlock>
          </BulletPoint>
          )}
      </Points>
        {props.children &&  <GridLines
          styles={{
              height: 174,
              heightMob: 60,
              marginTop: 28,
              marginTopMob: 25,
              marginBottom: 28,
              marginBottomMob: 28,
              color: "#F8F8F8",
          }}
        />}
        {props.children}
        <GridLines
            styles={{
                height: 87,
                heightMob: 20,
                marginTop: 28,
                marginTopMob: 25,
                marginBottom: 0,
                marginBottomMob: 0,
                color: "#F8F8F8",
            }}
        />
    </HeaderBlockWrap>
  )
}

export default HeaderBlock
