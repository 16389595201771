import React, {useState } from "react"
import { styled } from "@mui/system"
import ArrowLeftIcon from "../../static/svg/ArrowLeft.svg"
import ArrowRightIcon from "../../static/svg/ArrowRight.svg"
import { Label } from "../styles"
import NoiseBackground from "../../static/images/noize1.png"
import GridLines from "../gridLines"
import {GatsbyImage} from "gatsby-plugin-image";
import { useMediaQuery } from "@mui/material"

const SliderWrap = styled("div")({
  position: "relative",
  background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
  color: "white",
})

const Noise = styled("div")({
  position: "absolute",
  backgroundImage: `url(${NoiseBackground})`,
  width: "100%",
  height: "100%",
})

const Slider = styled("div")({
  maxWidth:"1280px",
  margin: "0 auto",
  marginTop: "67px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "31px",
  "@media(max-width: 1336px)": {
    padding: "0 28px"
  },
  "@media(max-width: 576px)": {
    marginTop: "5.12vw",
  },
})

const ArrowLeft = styled(ArrowLeftIcon)({
  flexShrink: 0,
  cursor: "pointer",
  zIndex: 4,

  "@media(max-width: 576px)": {
    width: "15px",
  },
})

const ArrowRight = styled(ArrowRightIcon)({
  flexShrink: 0,
  cursor: "pointer",
  zIndex: 4,

  "@media(max-width: 576px)": {
    width: "15px",
  },
})

const ContentWrap = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  margin: "0 19px",
  boxSizing: "border-box",
  "@media(max-width: 576px)":{
    margin: "0 4.87vw 0 6.86vw",
  }
})

const QuoteContainer = styled("div")(({ position }) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "50%",
  paddingRight: position === 'Right' ? "56px" : "0px",
  paddingLeft: position === 'Right' ? "12px" : "0px",

  "@media(max-width: 800px)": {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "25px",
  },
}))

const Quote = styled("div")({
  fontSize: "24px",
  lineHeight: "150%",

  "@media(max-width: 576px)": {
    fontSize: "14px",
    lineHeight: "150%",
  },
})

const FounderName = styled(Label)({
  textTransform: "uppercase",
  fontSize: "24px",
  paddingTop: "50px",

  "@media(max-width: 576px)": {
    fontSize: "14px",
    lineHeight: "14px",
    paddingTop: "25px",
  },
})

const Position = styled("div")({
  fontSize: "16px",
  lineHeight: "190%",
  marginLeft: "30px",
  paddingTop: "4px",
  opacity: 0.5,

  "@media(max-width: 576px)": {
    fontSize: "12px",
    margin: 0,
  },
})

const Dots = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: "20px",
})

const Dot = styled("div")({
  padding: "0 12px",
  cursor: "pointer",
})

function FounderQuotes({ data }) {
  const clean_data = data.edges.map(item => {
    const clean_node = {
      founder: {
        name: item.node.name,
        position: item.node.position,
        quote: item.node.quote,
        imagePosition: item.node.image_position
      },
      imageURL: item.node.image.localFile.childImageSharp.gatsbyImageData
    };
    return clean_node
  })
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(clean_data[0])
  const [isClicked, setIsClicked] = useState(false)

  const [startX, setStartX] = useState(null)
  const [moveX, setMoveX] = useState(null)

  const Content = styled("div")({
    maxWidth: "1270px",
    display: "flex",
    marginTop: "auto",
    minHeight: "487px",

    "@media(max-width: 800px)": {
      alignItems: "center",
      flexDirection: "column",
      minHeight: "300px",
    },

    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },

    "@keyframes fadeOut": {
      "0%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
      },
    }
  })

  const FounderImage = styled(GatsbyImage)(({ position }) => ({
    width: "50%",
    height: "auto",
    boxSizing: "border-box",
    opacity: 0.65,
    paddingRight: position === 'Right' ? "0px" : "44px",
    maxHeight: "487px",
    margin: "auto",

    "@media(max-width: 800px)": {
      width: "100%",
      paddingRight: "0px",
      height:"53.5vw",
      width: "53.5vw",
      minWidth: "209px",
      minHeight: "209px"
    },
  }))

  const previousSlide = () => {
    if (currentIndex !== 0) {
      setCurrentIndex(prev => prev - 1)
      setCurrentSlide(clean_data[currentIndex - 1])
      setIsClicked(true)
    }
  }

  const nextSlide = () => {
    if (currentIndex !== clean_data.length - 1) {
      setCurrentIndex(prev => prev + 1)
      setCurrentSlide(clean_data[currentIndex + 1])
      setIsClicked(true)
    }
  }

  const handleTouchStart = e => {
    setStartX(e.touches[0].clientX)
  }

  const handleTouchMove = e => {
    setMoveX(e.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (startX < moveX) {
      previousSlide()
    }
    if (startX > moveX) {
      nextSlide()
    }
  }

  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <SliderWrap
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Noise />
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginTop: 0,
          marginTopMob: 0,
          marginBottom: 0,
          marginBottomMob: 0,
          color: "rgba(255, 255, 255, .08)",
        }}
      />
      <Slider>
        <ArrowLeft onClick={previousSlide} style={currentIndex === 0 ? {opacity: .5, cursor: "default"} : null }/>
        <ContentWrap imgposition={currentSlide.founder.imagePosition}>
          <Content style={{animation: isClicked ? "fadeIn 0.5s ease-in" : "unset",}}>
            {currentSlide.founder.imagePosition !== "Right" || isMobile ? <FounderImage objectFit="contain" position={currentSlide.founder.imagePosition} alt={currentSlide.founder.name} image={currentSlide.imageURL}/> : null }
            <QuoteContainer position={currentSlide.founder.imagePosition}>
              <Quote>{currentSlide.founder.quote}</Quote>
              <FounderName>{"— " + currentSlide.founder.name}</FounderName>
              <Position>{currentSlide.founder.position}</Position>
            </QuoteContainer>
            {currentSlide.founder.imagePosition === "Right" && !isMobile ? <FounderImage objectFit="contain" position={currentSlide.founder.imagePosition} alt={currentSlide.founder.name} image={currentSlide.imageURL}/> : null }
          </Content>
          <Dots>
            {clean_data.map((slide, index) => {
              const isCurrent = index === currentIndex
              return (
                <Dot
                  key={index}
                  style={{
                    opacity: isCurrent ? 1 : 0.6,
                  }}
                  onClick={() => {setCurrentIndex(index); setCurrentSlide(clean_data[index])}}
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3" cy="3" r="3" fill="white" />
                  </svg>
                </Dot>
              )
            })}
          </Dots>
        </ContentWrap>
        <ArrowRight onClick={nextSlide} style={currentIndex === clean_data.length - 1 ? {opacity: .5, cursor: "default"} : null } />
      </Slider>
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginTop: 0,
          marginTopMob: 0,
          marginBottom: 0,
          marginBottomMob: 0,
          color: "rgba(255, 255, 255, .08)",
        }}
      />
    </SliderWrap>
  )
}

export default FounderQuotes
