import * as React from "react"
import Layout from "../components/layout"
import HeaderBlock, {
  Caption,
} from "../components/investorsAndFounders/headerBlock"
import OurValues from "../components/investorsAndFounders/ourValues"
import { graphql } from "gatsby"
import GlobalTeam from "../components/globalTeam"
import GridLines from "../components/gridLines"
import Portfolio from "../components/portfolio"
import FounderQuotes from "../components/investorsAndFounders/founderQuotes"
import { TextAccent } from "../components/styles"
import { useMediaQuery } from "@mui/material"
import MiddleEastInfo from "../components/investorsAndFounders/for-founders/middleEastInfo"
import ApplyForFunding from "../components/investorsAndFounders/for-founders/applyForFunding"
import Seo from "../components/seo"
import JobsBlock from "../components/investorsAndFounders/jobsBlock"
import Feedback from "../components/investorsAndFounders/for-investors/feedback"

const pointsData = [
  {
    label: "Sizable markets",
    body:
      "We invest in startups with sizable markets, that are struggling to generate high fund-level " +
      "returns, yet valued as capital-efficient businesses that have the ability to generate significant " +
      "cash flow and revenue.",
  },
  {
    label: "Bold entrepreneurs",
    body:
      "Our aim it to invest in outstanding entrepreneurs with bold ideas that can transform " +
      "industries and support their journey from inception to exit.",
  },
  {
    label: "Universal solutions",
    body:
      "We invest in the solutions that have the potential to scale up and cut across borders, " +
      "as humanity progresses towards a globally interconnected society.",
  },
]

const CaptionMessage = () => {
  const isMobile = useMediaQuery("(max-width: 576px)")

  return isMobile ? (
    <Caption>
      Our strategy is to follow a systematic approach, take a holistic view of
      the value chain, and select companies that{" "}
      <TextAccent>
        complement our portfolio where synergies can be created among them.{" "}
      </TextAccent>
      This strategy increases the chances of success for our portfolio companies
      versus the competition.
    </Caption>
  ) : (
    <Caption>
      Our strategy is to follow a systematic approach, take a holistic view of
      the <br />
      value chain, and select companies that{" "}
      <TextAccent>
        complement our portfolio where <br /> synergies can be created among
        them.{" "}
      </TextAccent>
      This strategy increases the chances <br /> of success for our portfolio
      companies versus the competition.
    </Caption>
  )
}

const questions = [
  {
    id: "isTechStartup",
    question: "Is your company a technology or technology-enabled startup?",
    options: ["yes", "no"],
  },
  {
    id: "whereBased",
    question: "Where is your startup based?",
    options: ["US", "MENA+P", "LATAM", "Other"],
  },
  {
    id: "fundingStage",
    question: "What stage of funding you are applying for?",
    options: ["Seed", "Series A", "Series B", "Other"],
  },
]

const ForFounders = ({ data }) => {
  return (
    <Layout headerDark={false}>
      <Seo
        title="Founders"
        description={`Our strategy is to follow a systematic approach, take a holistic view of the value chain, and select companies that complement our portfolio where synergies can be created among them.`}
      />
      <HeaderBlock
        title={"The Sparkle We Are Searching For"}
        points={pointsData}
      />
      <OurValues />
      <JobsBlock />
      <GridLines
        styles={{
          height: 87,
          heightMob: 60,
          marginBottom: 20,
          marginBottomMob: 25,
          color: "#F8F8F8",
        }}
      />
      <GlobalTeam data={data.strapiOurTeamOrder} />
      <GridLines
        styles={{
          height: 174,
          heightMob: 60,
          marginTop: 30,
          marginTopMob: 25,
          marginBottom: 70,
          marginBottomMob: 30,
          color: "#F8F8F8",
        }}
      />
      <MiddleEastInfo />
      <GridLines
        styles={{
          height: 174,
          heightMob: 60,
          marginTop: 66,
          marginTopMob: 25,
          marginBottom: 28,
          marginBottomMob: 25,
          color: "#F8F8F8",
        }}
      />
      <Portfolio data={data} text="every company" />
      <GridLines
        styles={{
          height: 87,
          heightMob: 60,
          marginTop: 36,
          marginTopMob: 25,
          color: "#F8F8F8",
        }}
      />
      <FounderQuotes data={data.allStrapiFoundersQuote} />
      <Feedback></Feedback>
    </Layout>
  )
}

export const query = graphql`
  query ForFoundersPageQuery {
    strapiOurTeamOrder {
      order {
        member_1 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_2 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_3 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_4 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_5 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allStrapiFoundersQuote(sort: { fields: rank, order: ASC }) {
      edges {
        node {
          rank
          position
          name
          quote
          image_position
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
              publicURL
            }
          }
        }
      }
    }
    allFile {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
    allStrapiCompany(sort: { fields: rank, order: ASC }) {
      edges {
        node {
          strapi_id
          name
          color
          description
          year_founded
          location
          stage
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
              publicURL
            }
          }
          preview_images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`

export default ForFounders
